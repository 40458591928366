/*---------------------------openemr messages--------------------------- */
@if $compact-theme == false {
  .oe-margin-t-3 {
    margin-top: 3px;
  }

  .oe-margin-t-10 {
    margin-top: 10px;
  }

  .oe-display {
    display: none;
  }

  .oe-margin-b-20 {
    margin-bottom: 20px;
  }

  .oe-margin-b-10 {
    margin-bottom: 10px;
  }

  a.arrowhead,
  a:hover.arrowhead,
  a:visited.arrowhead {
    color: $body-color;
  }

  .oe-cursor-stop {
    cursor: not-allowed;
  }

  .oe-cursor-add {
    cursor: cell;
  }

  .oe-patient-background {
    background: lighten($yellow, 80%) !important;
  }
}

/*---------------------------openemr messages--------------------------- */
